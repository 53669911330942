import React, { useState } from "react"
import { Button, Form, Row, Col, Spinner } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useAuthentication } from "@src/auth"
import { Page } from "@src/components"
import "./Login.css"
import { useNavigate } from "react-router-dom"
import { config } from "@src/constants"

const COLUMN = { marginTop: "10rem", borderRadius: 10 }

export const LoginScreen: React.FC = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [wrongcredentials, setWrongcredentials] = useState<string>("")
  const { loading, login } = useAuthentication()
  const navigate = useNavigate()

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value)
  }
  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value)
  }

  const onLoginButtonClick = (event: any) => {
    login(email, password).then(({ hasToken, token }) => {
      if (!hasToken) {
        setWrongcredentials(t("login_screen.wrong_credentials"))
      }
    })

    event.preventDefault()
  }

  const enabled = email.length > 0 && password.length > 0

  return (
    <Page fullscreen>
      <Row className="justify-content-md-center">
        <Col md="8" lg="6" className="shadow-lg bg-body" style={COLUMN}>
          <Form className="mt-4 mb-4 ms-3 me-3 loginform_2" onSubmit={onLoginButtonClick}>
            <Form.Group>
              <Form.Label className="fw-bold">{t("login_screen.login").toUpperCase()}</Form.Label>
              <Form.Control
                className="mb-3"
                type="email"
                placeholder={t("login_screen.email")}
                onChange={handleEmailChange}
                value={email}
              />
              <Form.Control
                className="mb-3"
                name="password"
                type="password"
                placeholder={t("login_screen.password")}
                onChange={handlePasswordChange}
                value={password}
              />
              {wrongcredentials && <div className="small text-danger">{wrongcredentials}</div>}
              <Button type="submit" value="submit" className="text-white" disabled={!enabled}>
                {loading ? (
                  <Spinner animation="border" size="sm" variant="light" />
                ) : (
                  t("login_screen.login").toUpperCase()
                )}
              </Button>
              <Button onClick={() => navigate("/request-access")} className="ms-3 btn btn-primary-outline">
                {t("login_screen.request_access").toUpperCase()}
              </Button>
              <a
                className="pointer ms-3"
                onClick={() => {
                  const url = config.passwordRedirectionURL
                  window.open(url, "_blank")
                }}>
                {t("login_screen.forgot_password")}
              </a>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Page>
  )
}

import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Col, Row, Spinner } from "react-bootstrap"
import { Page } from "@src/components"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { getDevice } from "@src/api"
import { useLocalization } from "@src/localization"
import { useTranslation } from "react-i18next"
import { usePartner } from "@src/partner"
import { useFleets } from "@src/settings"
import { colors, config } from "@src/constants"
import {
  PedalBike,
  DirectionsBike,
  Speed,
  Report,
  ModeOfTravel,
  Lock,
  DirectionsRun,
  Co2,
  LocationOn,
} from "@mui/icons-material"
import {
  getFleetCo2,
  getFleetDistance,
  getFleetMovementsCounts,
  getFleetPositionCounts,
  getFleetSpeed,
  getFleetTrip,
  getFleetTripCounts,
} from "@src/api/requests/statistics"
import { StatisticCard, DevicesCard } from "./components"
import { centerAlign } from "@src/device/devicedetail/tabs/components"
import "./Dashboard.css"
import { StyleSheet } from "@src/types"
import { SYSTEM_OF_MEASUREMENT, useUser } from "@src/user"
import { getPartnerExpiredStatistics } from "@src/api/requests/statistics/getPartnerStatistics"

const DAY_IN_MILLISECONDS: number = 24 * 60 * 60 * 1000

type STATISTICS_DATA = {
  trip_count: string
  movement_count: string
  position_count: string
  avg_distance: string
  total_distance: string
  avg_co2: string
  total_co2: string
  avg_trips: string
  total_trips: string
  avg_speed: string
  total_speed: string
}

let fleetStatisticsData: STATISTICS_DATA = {
  trip_count: "",
  movement_count: "",
  position_count: "",
  avg_distance: "",
  total_distance: "",
  avg_co2: "",
  total_co2: "",
  avg_trips: "",
  total_trips: "",
  avg_speed: "",
  total_speed: "",
}

const styles: StyleSheet = {
  backgroundDrop: {
    backgroundColor: "rgba(0,0,0,0.3)",
    width: "100%",
    height: "100%",
    zIndex: 1,
    top: 0,
    left: 0,
    bottom: 0,
    borderRadius: ".25rem",
    position: "absolute",
  },
  loaderView: {
    paddingTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
    display: "flex",
    backgroundColor: colors.smokeWhite,
    height: "65px",
    zIndex: 999,
    borderRadius: 8,
    flexDirection: "column",
  },
}

export const DashboardScreen: React.FC = () => {
  const navigate = useNavigate()
  const { formatDate } = useLocalization()
  const { t } = useTranslation()
  const { partner, brand } = usePartner()
  const [devices, setDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [statisticsData, setStatisticsData] = useState<STATISTICS_DATA>(fleetStatisticsData)
  const [unit, setUnit] = useState<SYSTEM_OF_MEASUREMENT>()
  const { fleet } = useFleets()
  const { user, distanceConversion } = useUser()
  const [expiredDevices, setExpiredDevices] = useState<SLIM_DEVICE_TYPE[]>([])

  useEffect(() => {
    if (partner) {
      // getPartnerExpired()
      fetchDevices()
    }
  }, [partner, fleet])

  useEffect(() => {
    if (user) setUnit(user.system_of_measurement)
  }, [user])

  // const getPartnerExpired = async () => {
  //   setLoading(true)
  //   getPartnerExpiredStatistics(partner?.uuid, {
  //     partner_id: partner?.uuid,
  //     page: 0,
  //     per_page: 20,
  //     months: 2,
  //   }).then(({ response, data }) => {
  //     console.log("expired devices", data)
  //     if (response.ok) {
  //       setExpiredDevices(data.devices)
  //     }
  //   })
  // }

  const fetchDevices = async () => {
    setLoading(true)
    getDevice(partner?.uuid, fleet && fleet.uuid, {
      per_page: config.itemsPerPage,
    }).then(({ response, data }) => {
      if (response.ok) {
        setDevices(data.devices)
        if (!fleet) {
          setLoading(false)
        }
        getFleetsStatistics()
      }
    })
  }

  const getFleetsStatistics = async () => {
    const partnerUUid = partner?.uuid
    const fleetUUid = fleet?.uuid
    if (!partnerUUid || !fleetUUid) {
      setLoading(false)
      return
    }
    const fleetTrip = (await Promise.resolve(getFleetTripCounts(partnerUUid, fleetUUid))).data
    const fleetMovement = (await Promise.resolve(getFleetMovementsCounts(partnerUUid, fleetUUid))).data
    const fleetPosition = (await Promise.resolve(getFleetPositionCounts(partnerUUid, fleetUUid))).data
    const fleetDistance = (await Promise.resolve(getFleetDistance(partnerUUid, fleetUUid))).data
    const fleetCo2 = (await Promise.resolve(getFleetCo2(partnerUUid, fleetUUid))).data
    const fleetTripDetail = (await Promise.resolve(getFleetTrip(partnerUUid, fleetUUid))).data
    const fleetSpeed = (await Promise.resolve(getFleetSpeed(partnerUUid, fleetUUid))).data

    Promise.all([fleetTrip, fleetMovement, fleetPosition, fleetDistance, fleetCo2, fleetTripDetail, fleetSpeed]).then(
      (values) => {
        fleetStatisticsData.trip_count = `${values[0]?.count}`
        fleetStatisticsData.movement_count = `${values[1]?.count}`
        fleetStatisticsData.position_count = `${values[2]?.count}`
        fleetStatisticsData.avg_distance = `${distanceConversion(values[3]?.avg_distance)}`
        fleetStatisticsData.total_distance = `${distanceConversion(values[3]?.total_distance)}`
        fleetStatisticsData.avg_co2 = `${Math.round(values[4]?.avg_co2)} ${values[4]?.avg_co2_unit}`
        fleetStatisticsData.total_co2 = `${Math.round(values[4]?.total_co2)} ${values[4]?.total_co2_unit}`
        fleetStatisticsData.avg_trips = `${values[5]?.avg_trips}`
        fleetStatisticsData.total_trips = `${values[5]?.total_trips}`
        fleetStatisticsData.avg_speed = `${values[6]?.avg_speed} ${values[6]?.avg_speed_unit}`
        fleetStatisticsData.total_speed = `${values[6]?.total_speed} ${values[6]?.total_speed_unit}`
        setStatisticsData(fleetStatisticsData)
        setLoading(false)
      },
    )
  }

  const onDeviceClick = (device: SLIM_DEVICE_TYPE) => {
    navigate(`/${partner?.uuid}/device/${device.serialnumber}`)
  }

  const totalDevices = devices.filter((device) => device.servicebooking !== null).length
  const reportedThefts = devices.filter((device) => !!device.reported_theft).length
  const lockedDevices = devices.filter((device) => device.locked === "locked").length
  const lowBatteryDevices = devices.filter((device) => device.battery < 10)
  const noServiceBookingDevices = devices.filter((device) => device.servicebooking === null)
  const runningOutServiceBookingDevices = devices.filter(
    (device) =>
      device.servicebooking !== null &&
      new Date(device.servicebooking.service_end_date).getTime() - new Date().getTime() <= 60 * DAY_IN_MILLISECONDS,
  )

  const renderLoaderSpinner = () => {
    return (
      <>
        <div className={centerAlign} style={styles.backgroundDrop}>
          <div className={centerAlign} style={styles.loaderView}>
            <Spinner className="align-self-center" animation="border" size="sm" />
            <b className="m-2">{t("dashboard_screen.loader")}</b>
          </div>
        </div>
      </>
    )
  }

  return (
    <Page
      showFleet
      showHeader
      isRelative
      headerValue={t("sidebar.statistics")}
      onSelectFleet={() => {
        setLoading(true)
        setDevices([])
      }}>
      {loading && renderLoaderSpinner()}
      <Row>
        <StatisticCard
          show={true}
          children={<PedalBike />}
          title={t("dashboard_screen.activated_devices")}
          totalCount={totalDevices}
          loading={loading}
          isDeviceActive={true}
          onButtonClick={() => navigate(`/${partner?.uuid}/settings/partner?tab=add`)}
        />
        <StatisticCard
          show={true}
          children={<Lock />}
          title={t("dashboard_screen.locked_devices")}
          totalCount={lockedDevices}
          loading={loading}
        />
        {fleet && fleet.uuid !== undefined ? (
          <StatisticCard
            show={fleet && fleet.uuid !== undefined}
            children={<ModeOfTravel />}
            title={t("dashboard_screen.devices_movement")}
            totalCount={statisticsData.movement_count}
            loading={loading}
          />
        ) : (
          <StatisticCard
            show={true}
            children={<Report />}
            title={t("dashboard_screen.reported_thefts")}
            totalCount={reportedThefts}
            loading={loading}
          />
        )}
      </Row>
      <Row>
        <StatisticCard
          show={fleet && fleet.uuid !== undefined}
          children={<DirectionsBike />}
          title={t("dashboard_screen.devices_trip")}
          isDetailed={true}
          totalCount={statisticsData.trip_count}
          titles={[t("dashboard_screen.average_trips"), t("dashboard_screen.total_trips")]}
          detailCounts={[statisticsData.avg_trips, statisticsData.total_trips]}
          loading={loading}
        />
        <StatisticCard
          show={fleet && fleet.uuid !== undefined}
          children={<LocationOn />}
          title={t("dashboard_screen.devices_position")}
          totalCount={statisticsData.position_count}
          loading={loading}
        />
        <StatisticCard
          show={fleet && fleet.uuid !== undefined}
          children={<DirectionsRun />}
          title={t("dashboard_screen.devices_distance")}
          isDetailed={true}
          titles={[t("dashboard_screen.average_distance"), t("dashboard_screen.total_distance")]}
          detailCounts={[statisticsData.avg_distance, statisticsData.total_distance]}
          loading={loading}
        />
      </Row>
      <Row>
        <StatisticCard
          show={fleet && fleet.uuid !== undefined}
          children={<Co2 />}
          title={t("dashboard_screen.devices_co2")}
          isDetailed={true}
          titles={[t("dashboard_screen.average_co2"), t("dashboard_screen.total_co2")]}
          detailCounts={[statisticsData.avg_co2, statisticsData.total_co2]}
          loading={loading}
        />
        <StatisticCard
          show={fleet && fleet.uuid !== undefined}
          children={<Speed />}
          title={t("dashboard_screen.devices_speed")}
          isDetailed={true}
          titles={[t("dashboard_screen.average_speed")]}
          detailCounts={[statisticsData.avg_speed]}
          loading={loading}
        />
        <StatisticCard
          show={fleet && fleet.uuid !== undefined}
          children={<Report />}
          title={t("dashboard_screen.reported_thefts")}
          totalCount={reportedThefts}
          loading={loading}
        />
      </Row>
      <Row className="mb-3 mt-2">
        <Col lg={4}>
          <DevicesCard
            title={t("dashboard_screen.devices_with_critical_battery")}
            devices={lowBatteryDevices}
            loading={loading}
            onDeviceClick={onDeviceClick}
            formatDeviceTitle={(device) => `${device.name} - ${device.battery} %`}
          />
        </Col>
        <Col lg={4}>
          <DevicesCard
            title={t("dashboard_screen.devices_withouth_servicebooking")}
            devices={noServiceBookingDevices}
            loading={loading}
            onDeviceClick={onDeviceClick}
            formatDeviceTitle={(device) => `${device.serialnumber} - ${device.name}`}
          />
        </Col>
        <Col lg={4}>
          <DevicesCard
            title={t("dashboard_screen.devices_with_ending_servicebookings", { value: "60" })}
            devices={runningOutServiceBookingDevices}
            loading={loading}
            showDetail={true}
            onDetailClick={(devices) => {
              navigate(`/${partner?.uuid}/devicesexpiringsoon`, {
                state: { devices },
              })
            }}
            onDeviceClick={onDeviceClick}
            formatDeviceTitle={(device) =>
              `${device.name}  ${
                device?.servicebooking ? "- " + formatDate(new Date(device?.servicebooking?.service_end_date)) : ""
              }`
            }
          />
        </Col>
      </Row>
    </Page>
  )
}

import { getFleetsUuidSettings, NOTIFICATION_SETINGS, postFleetsUuidSettings } from "@src/api"
import { sortArrowStyles } from "@src/device/devicedetail/tabs/components"
import { usePartner } from "@src/partner"
import React, { useEffect, useState } from "react"
import { Form, ListGroupItem, Spinner, ListGroup } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useFleets } from "../useFleets"

const divWidth = { width: "16px" }

export const FleetNotificationScreen: React.FC = () => {
  const { t } = useTranslation()
  const { partner } = usePartner()
  const { fleet } = useFleets()
  const [loading, setLoading] = useState<boolean>(false)
  const [settingsData, setSettingsData] = useState<NOTIFICATION_SETINGS>()
  const [selectedKey, setSelectedKey] = useState<any>()
  const settings = [
    {
      key: "battery_low",
      value: t("settings.fleets.notifications.battery_low"),
      description: t("settings.fleets.notifications.battery_low_desc"),
    },
    {
      key: "battery_very_low",
      value: t("settings.fleets.notifications.battery_very_low"),
      description: t("settings.fleets.notifications.battery_very_low_desc"),
    },
    {
      key: "locked_distance",
      value: t("settings.fleets.notifications.locked_distance"),
      description: t("settings.fleets.notifications.locked_distance_desc"),
    },
    {
      key: "locked_movement",
      value: t("settings.fleets.notifications.locked_movement"),
      description: t("settings.fleets.notifications.locked_movement_desc"),
    },
  ]

  useEffect(() => {
    setLoading(true)
    if (partner && fleet) getSettings()
  }, [partner, fleet])

  const getSettings = () => {
    getFleetsUuidSettings(partner.uuid, fleet.uuid).then(({ response, data }) => {
      setLoading(false)
      if (response.ok) {
        setSettingsData(data)
      }
    })
  }

  const postSettings = (key: string) => {
    setSelectedKey(key)
    postFleetsUuidSettings(partner.uuid, fleet.uuid, { [key]: !settingsData[key] }).then(({ response, data }) => {
      setSelectedKey("")
      let postData = { ...settingsData, [key]: !settingsData[key] }
      setSettingsData(postData)
      if (response.ok) {
        getSettings()
      }
    })
  }

  return (
    <div>
      {loading ? (
        <div className="align-items-center d-flex justify-content-center m-5">
          <Spinner animation={"border"} />
        </div>
      ) : (
        <ListGroup className="pointer">
          {settings.map((item) => (
            <ListGroupItem key={item.key} action onClick={() => postSettings(item.key)}>
              <div className="d-flex align-items-center flex-row">
                <div style={divWidth}>
                  {selectedKey == item.key ? (
                    <Spinner animation="border" style={sortArrowStyles.loader} />
                  ) : (
                    <Form.Check
                      type="checkbox"
                      checked={settingsData && settingsData[item.key] == true}
                      onChange={() => postSettings(item.key)}
                    />
                  )}
                </div>
                <b className="ms-3">{item.value}</b>
              </div>
              <div className="d-flex flex-column mt-1">
                <label className="small pointer">{item.description}</label>
              </div>
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  )
}

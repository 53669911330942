import React, { createContext, useContext, useEffect, useState } from "react"
import { useAuthentication } from "@src/auth"
import { usePersistedState } from "@src/utils"
import { getPartner, getPartnerUuid, getPartnerUuidRolesMe, ROLES_TITLE } from "@src/api"
import { useNavigate } from "react-router-dom"
import { colors } from "@src/constants"
import { useFleets } from "@src/settings"
import { env } from "@src/utils"
import { BrandDomain } from "@src/types"
import brandingConfig from "@src/branding/config.json"

type BRAND_TYPE = {
  name: string
  logoUrl: string
  color: string
  backgroundImage?: string
}
const DEFAULT_BRAND: BRAND_TYPE = {
  name: "Dashboard | IoT-Venture GmbH",
  logoUrl: "https://res.cloudinary.com/iot-venture/image/upload/v1660807621/iot-venture-banner.png",
  color: colors.black,
  backgroundImage: "/assets/bikedealer_background_white.jpg",
}

type PARTNER_PERMISSIONS = {
  can_see_map?: boolean
  can_see_energy_saving?: boolean
  can_see_device_list?: boolean
  can_see_device_statistics?: boolean
  can_see_device_routes?: boolean
}

const DEFAULT_PARTNER_PERMISSIONS = {
  can_see_map: true,
  can_see_energy_saving: true,
  can_see_device_list: true,
  can_see_device_statistics: true,
  can_see_device_routes: true,
}

export type PARTNER = {
  uuid: string
  name: string
  description: string
  logo_url: string
  color: string
  contact: string
  permissions: PARTNER_PERMISSIONS
}

type PERMISSIONS = {
  viewTheftContact?: boolean
  editTheftContact?: boolean
  createTheftContact?: boolean
  useEnergySaving?: boolean
  editDevices?: boolean
  editGeofence?: boolean
  createFleets?: boolean
  editFleets?: boolean
  manageUserRoles?: boolean
  manageFleetPermission?: boolean
  editBrandSettings?: boolean
  viewTrips?: boolean
  viewStatistics?: boolean
  viewDevices?: boolean
  lockUnlockDevices?: boolean
}

const DEFAULT_PERMISSIONS = {
  viewTheftContact: false,
  editTheftContact: false,
  createTheftContact: false,
  useEnergySaving: false,
  editDevices: false,
  editGeofence: false,
  createFleets: false,
  editFleets: false,
  manageUserRoles: false,
  manageFleetPermission: false,
  editBrandSettings: false,
  viewTrips: false,
  viewStatistics: false,
  viewDevices: false,
  lockUnlockDevices: false,
}

type PARTNER_CONTEXT = {
  brand: BRAND_TYPE
  partners: PARTNER[]
  partner: PARTNER
  devicesLength: number
  hasPartner: boolean
  permissions: PERMISSIONS
  updatePartners: () => void
  clearPartner: () => void
  changePartner: (id: string) => void
  updateDeviceLength: (length: number) => void
}

const DEFAULT_PARTNER_CONTEXT = {
  brand: DEFAULT_BRAND,
  partners: [],
  partner: null,
  devicesLength: 20,
  hasPartner: false,
  permissions: DEFAULT_PERMISSIONS,
  updatePartners: () => {},
  clearPartner: () => {},
  changePartner: () => {},
  updateDeviceLength: () => {},
}

export const PartnerContext = createContext<PARTNER_CONTEXT>(DEFAULT_PARTNER_CONTEXT)

export const PartnerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuthentication()
  const [partners, setPartners, partnersHasBeenLoaded] = usePersistedState<PARTNER[]>("@partners", [])
  const [partner, setPartner] = usePersistedState<PARTNER>("@partner", null)
  const findPartner = (uuid: string, partnersList = partners) => partnersList.find((partner) => partner.uuid === uuid)
  const { updateFleetUuid } = useFleets()
  const [devicesLength, setDevicesLength] = usePersistedState<number>("@devicesLength", 20)
  const [permissions, setPermissions] = useState<PERMISSIONS>(DEFAULT_PERMISSIONS)
  const currentDomain = window.location.hostname

  // Use state to manage the dynamic brand
  const [brand, setBrand] = useState<BRAND_TYPE>(DEFAULT_BRAND)

  const updateBrandStyles = (color: string) => {
    document.documentElement.style.setProperty("--navbar-bg-color", color)
    document.documentElement.style.setProperty("--brand-primary-color", color)
  }

  useEffect(() => {
    const brands = brandingConfig.partners
    const domainpartner = Object.values(brands).find((item) => item.domain === currentDomain)
    if (partner) {
      // Partner-based branding fallback
      const color = partner?.color?.includes("#") ? partner.color : `#${partner?.color || DEFAULT_BRAND.color}`
      setBrand({
        name: partner?.name,
        logoUrl: partner?.logo_url,
        color: color,
      })

      // remove the background image
      const loginBackgroundElement = document.getElementById("login-background-div") as HTMLDivElement
      if (loginBackgroundElement) {
        loginBackgroundElement.style.backgroundImage = "none"
      }

      updateBrandStyles(color)
    } else if (domainpartner) {
      // Domain-based branding
      const { branding } = domainpartner
      setBrand({
        name: domainpartner.name,
        logoUrl: branding.logo.url,
        color: branding.primaryColor,
      })
      document.title = domainpartner.name
      // Optionally update the login background
      const loginBackgroundElement = document.getElementById("login-background-div") as HTMLDivElement
      if (loginBackgroundElement) {
        loginBackgroundElement.style.backgroundImage = `url(${branding.backgroundImage})`
        loginBackgroundElement.style.backgroundSize = "cover"
        loginBackgroundElement.style.backgroundPosition = "center"
      }
      updateBrandStyles(branding.primaryColor)
    } else {
      // Default branding
      setBrand(DEFAULT_BRAND)
      const loginBackgroundElement = document.getElementById("login-background-div") as HTMLDivElement
      if (loginBackgroundElement) {
        loginBackgroundElement.style.backgroundImage = `url(${DEFAULT_BRAND.backgroundImage})`
      }
      updateBrandStyles(DEFAULT_BRAND.color)
    }
  }, [currentDomain, partner])

  useEffect(() => {
    // Update the document title and styles when brand changes
    document.title = brand?.name
    document.documentElement.style.setProperty("--brand-primary-color", brand?.color)
  }, [brand])

  useEffect(() => {
    if (isAuthenticated) {
      setTimeout(() => {
        updatePartners()
      }, 0)
    } else {
      setPartners([])
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (partner) updateUserRoles(partner.uuid)
  }, [partner])

  const updatePartners = (): Promise<PARTNER[]> => {
    return getPartner().then(({ response, data }) => {
      if (response.ok) {
        setPartners(data)
        return data
      }
    })
  }

  const updateUserRoles = (uuid: string) => {
    getPartnerUuidRolesMe(uuid).then(({ response, data }) => {
      if (response.ok) {
        if (data) {
          if (data.name === ROLES_TITLE.role_admin) {
            constructPermission(ROLES_TITLE.role_admin)
          } else {
            data.name === ROLES_TITLE.role_manager
              ? constructPermission(ROLES_TITLE.role_manager)
              : constructPermission(ROLES_TITLE.role_user)
          }
        } else {
          setPermissions(DEFAULT_PERMISSIONS)
        }
      }
    })
  }

  const constructPermission = (role: string) => {
    let permissionsData: PERMISSIONS = {}
    if (role === ROLES_TITLE.role_admin) {
      permissionsData = {
        viewTheftContact: true,
        editTheftContact: true,
        createTheftContact: true,
        useEnergySaving: true,
        editDevices: true,
        editGeofence: true,
        createFleets: true,
        editFleets: true,
        manageUserRoles: true,
        manageFleetPermission: true,
        editBrandSettings: true,
        viewTrips: true,
        viewStatistics: true,
        viewDevices: true,
        lockUnlockDevices: true,
      }
    } else if (role === ROLES_TITLE.role_manager) {
      permissionsData = {
        ...DEFAULT_PERMISSIONS,
        viewTheftContact: true,
        editTheftContact: true,
        createTheftContact: true,
        useEnergySaving: true,
        editDevices: true,
        editGeofence: true,
        createFleets: true,
        editFleets: true,
        viewTrips: true,
        viewStatistics: true,
        viewDevices: true,
        lockUnlockDevices: true,
      }
    } else {
      permissionsData = {
        ...DEFAULT_PERMISSIONS,
        viewTrips: true,
        viewStatistics: true,
        viewDevices: true,
        lockUnlockDevices: true,
      }
    }
    setPermissions(permissionsData)
  }

  const changePartner = async (uuid: string) => {
    setPartner(null)
    loadPartner(uuid)
  }

  const loadPartner = (uuid: string) => {
    getPartnerUuid(uuid).then(({ response, data }) => {
      if (response.ok) {
        let dancePartnerUuid = env === "production" ? "a14fe0" : "be084f"
        if (data.uuid === dancePartnerUuid) {
          data["permissions"] = {
            can_see_map: false,
            can_see_energy_saving: true,
            can_see_device_list: true,
            can_see_device_statistics: true,
            can_see_device_routes: true,
          }
        } else data["permissions"] = DEFAULT_PARTNER_PERMISSIONS
        document.documentElement.style.setProperty("--navbar-bg-color", data.color)
        document.documentElement.style.setProperty("--brand-primary-color", data.color)
        document.title = data.name
        setBrand({
          name: data.name,
          logoUrl: data.logo_url,
          color: data.color,
        })
        setPartner(data)
      } else {
        // No authorization
        setPartner(findPartner(uuid))
      }
    })
    updateFleetUuid("")
    updateUserRoles(uuid)
    navigate(`/${uuid}`)
  }

  const clearPartner = () => {
    setPartner(null)
  }

  const updateDeviceLength = (length: number) => {
    setDevicesLength(length)
  }

  const context = {
    brand,
    partner,
    partners,
    devicesLength,
    hasPartner: Boolean(partner),
    permissions,
    updatePartners,
    clearPartner,
    changePartner,
    updateDeviceLength,
  }

  return <PartnerContext.Provider value={context}>{children}</PartnerContext.Provider>
}

export const usePartner = () => useContext(PartnerContext)

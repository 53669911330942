import { callApi } from "../../callApi"

export type GET_STATISTICS_COUNT_RESPONSE = {
  count: number
}

export type GET_DISTANCE_RESPONSE = {
  avg_distance: number
  avg_distance_unit: string
  total_distance: number
  total_distance_unit: string
}

export type GET_CO2_RESPONSE = {
  avg_co2: number
  avg_co2_unit: string
  total_co2: number
  total_co2_unit: string
}

export type GET_TRIP_RESPONSE = {
  avg_trips: number
  total_trips: number
}

export type GET_SPEED_RESPONSE = {
  avg_speed: number
  avg_speed_unit: string
  total_speed: number
  total_speed_unit: string
}

export const getFleetTripCounts = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_STATISTICS_COUNT_RESPONSE>({
    method: "GET",
    path: `/partner/${partnerUuid}/fleets/${fleetUuid}/statistics/devices_with_trip_count`,
  })

export const getFleetMovementsCounts = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_STATISTICS_COUNT_RESPONSE>({
    method: "GET",
    path: `/partner/${partnerUuid}/fleets/${fleetUuid}/statistics/devices_with_movement_count`,
  })

export const getFleetPositionCounts = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_STATISTICS_COUNT_RESPONSE>({
    method: "GET",
    path: `/partner/${partnerUuid}/fleets/${fleetUuid}/statistics/devices_with_valid_position_count`,
  })

export const getFleetDistance = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_DISTANCE_RESPONSE>({
    method: "GET",
    path: `/partner/${partnerUuid}/fleets/${fleetUuid}/statistics/distance`,
  })

export const getFleetCo2 = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_CO2_RESPONSE>({
    method: "GET",
    path: `/partner/${partnerUuid}/fleets/${fleetUuid}/statistics/co2`,
  })

export const getFleetTrip = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_TRIP_RESPONSE>({
    method: "GET",
    path: `/partner/${partnerUuid}/fleets/${fleetUuid}/statistics/trips`,
  })

export const getFleetSpeed = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_SPEED_RESPONSE>({
    method: "GET",
    path: `/partner/${partnerUuid}/fleets/${fleetUuid}/statistics/speed`,
  })

import React from "react"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { Card, ListGroup, Spinner } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { usePartner } from "@src/partner"
import { sortArrowStyles } from "@src/device/devicedetail/tabs/components"

const styles = {
  cardBody: {
    display: "flex",
    flexDirection: "column" as const, // (as const) is a typescript issue workaround: 11.07.2022
    height: "250px",
  },
  listGroup: {
    overflow: "scroll",
    flex: 1,
    display: "flex",
  },
}

type DevicesCardProps = {
  title: string
  devices: SLIM_DEVICE_TYPE[]
  loading: boolean
  showDetail?: boolean
  onDetailClick?: (devices: SLIM_DEVICE_TYPE[]) => void
  onDeviceClick: (device: SLIM_DEVICE_TYPE) => void
  formatDeviceTitle: (device: SLIM_DEVICE_TYPE) => string
}

const pageClassName = "shadow-2 bg-white rounded"

export const DevicesCard: React.FC<DevicesCardProps> = ({
  title,
  devices,
  loading,
  showDetail,
  onDetailClick,
  onDeviceClick,
  formatDeviceTitle,
}) => {
  const { t } = useTranslation()
  const { brand } = usePartner()
  const stylesBorder = {
    options: {
      borderWidth: 1.5,
      borderColor: brand.color,
      borderRadius: "25px",
    },
  }
  return (
    <Card style={stylesBorder.options} className={pageClassName}>
      <Card.Body style={styles.cardBody}>
        <div className="d-flex justify-content-between">
          <Card.Title className="fs-5">{title}</Card.Title>
        </div>

        <ListGroup className="mt-2" style={styles.listGroup}>
          {devices.length === 0 && !loading ? (
            <ListGroup.Item>{t("dashboard_screen.no_devices")}</ListGroup.Item>
          ) : (
            <>
              {devices.map((device) => (
                <ListGroup.Item
                  key={device.serialnumber}
                  className="list-group-item list-group-item-action pointer"
                  as="a"
                  onClick={() => onDeviceClick(device)}>
                  {formatDeviceTitle(device)}
                </ListGroup.Item>
              ))}
            </>
          )}
          {loading && devices.length == 0 && <Spinner style={sortArrowStyles.loader} animation="border" />}
        </ListGroup>
        <div className="d-flex flex-row-reverse">
          {showDetail && (
            <label className="pointer" onClick={() => onDetailClick(devices)}>
              {t("dashboard_screen.detail")}
            </label>
          )}
        </div>
      </Card.Body>
    </Card>
  )
}

import { useTranslation } from "react-i18next"
import { AsyncTypeahead } from "react-bootstrap-typeahead"
import _ from "lodash"
import { useState } from "react"
import { map } from "@src/config"

type FENCEPOST = {
  lat: number
  lng: number
}

type SearchCityFenceProps = {
  onSearchResult: (coordinates: FENCEPOST[]) => void
}

export const SearchCityFence: React.FC<SearchCityFenceProps> = ({ onSearchResult }) => {
  const { t } = useTranslation()
  const [options, setOptions] = useState()

  const handleSearch = (query) => {
    if (!query) {
      return
    }
    fetch(`${map.openStreetQueryUrl}${query}`)
      .then((resp) => resp.json())
      .then((data) => {
        let filterGeoJsonType = data.filter(function (data) {
          return data.geojson.type === "MultiPolygon" || data.geojson.type === "Polygon"
        })
        setOptions(filterGeoJsonType)
      })
  }

  const renderCoordinate = (paths) => {
    var coordinates = []
    let position = 0
    paths.map((location) => {
      if (position % 10 === 0) {
        coordinates.push({ lat: location[1], lng: location[0] })
      }
      position++
      return true
    })
    onSearchResult(coordinates)
  }

  const handleChange = (selectedOptions) => {
    selectedOptions.forEach((option) => {
      if (option.geojson.type === "MultiPolygon") {
        renderCoordinate(option.geojson.coordinates[0][0])
      } else if (option.geojson.type === "Polygon") {
        renderCoordinate(option.geojson.coordinates[0])
      } else {
        alert("option.geojson.type: MultiPolygon & Polygon")
      }
    })
  }

  const renderDiv = (options) => {
    return <div>{options.display_name}</div>
  }

  return (
    <AsyncTypeahead
      className="mt-2 flex-row"
      id="type_ahead"
      isLoading={false}
      options={options}
      labelKey="display_name"
      onSearch={_.debounce(handleSearch, 2000)}
      onChange={handleChange}
      placeholder={t("settings.fence.search_city")}
      renderMenuItemChildren={(option, props, index) => renderDiv(option)}
    />
  )
}

import brandingConfig from "@src/branding/config.json";

// Extract the partner domains from the config file
const productionDomains = [
  "dashboard.iot-venture.com",
  ...Object.values(brandingConfig.partners).map((partner) => partner.domain),
];

// Determine the environment
export const env: "production" | "staging" = productionDomains.includes(window.location.hostname)
  ? "production"
  : "staging"

// export const env: "production" | "staging" =
//   window.location.hostname == "dashboard.iot-venture.com" ? "production" : "staging"

import { DATA_POINT_TYPE } from "@src/api"

export const mToKm = (value) => `${(value / 1000).toFixed(1)} km`
export const sToH = (value) => {
  const time = value / 60 / 60
  const h = Math.floor(time)
  const m = (time - h) * 60
  return time.toFixed(2) //`${h}:${m}`
}
export const gToKg = (value) => (value / 1000).toFixed(2)
export const generateIntervals = (totalCount) => {
  let totalLength = Math.ceil(totalCount / 50)
  let intervalArray = [20]
  for (let index = 0; index < totalLength; index++) {
    intervalArray.push((index + 1) * 50)
  }
  return intervalArray
}
export const yardToMiles = (value) => `${(value / 1760).toFixed(1)} mi`

export const checkIsCanTracker = (datapoints: DATA_POINT_TYPE[]) => {
  let isCanTracker = false
  for (let i = 0; i < datapoints.length; i++) {
    const datapoint = datapoints[i]
    if (
      datapoint.grit_value === 0 ||
      datapoint.grit_value === 1 ||
      datapoint.grit_value === 2 ||
      typeof datapoint.pump_active_flag === "boolean" ||
      datapoint.working_width_value
    ) {
      isCanTracker = true
      break
    }
  }
  return isCanTracker
}

import React from "react"
import { Route, Routes, Navigate } from "react-router-dom"
import { AccessRequestScreen, useAuthentication } from "@src/auth"
import { LoginScreen } from "@src/auth/LoginScreen"
import { DashboardScreen } from "@src/dashboard"
import { DevicesScreen } from "@src/device"
import { TheftReportScreen } from "@src/theftreport"
import { ImprintScreen, PrivacyPolicyScreen } from "@src/legal"
import { DeviceDetailScreen } from "@src/device/devicedetail/DeviceDetailScreen"
import { SelectPartnerScreen } from "@src/partner"
import { MapScreen } from "@src/maps"
import { EnergySaving } from "@src/energysave"
import {
  CreateGeofenceScreen,
  EditFenceScreen,
  FenceListScreen,
  FleetsDetailScreen,
  FleetsScreen,
  PartnerSettingsScreen,
  UserSettingsScreen,
} from "@src/settings"
import { ExpiresSoon } from "@src/dashboard/ExpireSoonDevices"

const privateComponent = (element: JSX.Element): JSX.Element => {
  const { isAuthenticated, tokenHasBeenLoaded } = useAuthentication()
  return isAuthenticated || !tokenHasBeenLoaded ? element : <Navigate to={"/login"} replace />
}

const ApplicationRouter = () => {
  const { isAuthenticated } = useAuthentication()

  return (
    <Routes>
      <Route path="/login" element={!isAuthenticated ? <LoginScreen /> : <Navigate to="/" replace />} />
      <Route path="/request-access" element={<AccessRequestScreen />} />
      <Route path="/" element={privateComponent(<SelectPartnerScreen />)} />

      <Route path="/:partnerUuid" element={privateComponent(<DashboardScreen />)} />
      <Route path="/:partnerUuid/devices" element={privateComponent(<DevicesScreen />)} />
      <Route path="/:partnerUuid/devicesexpiringsoon" element={privateComponent(<ExpiresSoon />)} />
      <Route path="/:partnerUuid/map" element={privateComponent(<MapScreen />)} />
      <Route path="/:partnerUuid/theft" element={privateComponent(<TheftReportScreen />)} />
      <Route path="/:partnerUuid/energysaving" element={privateComponent(<EnergySaving />)} />

      <Route path="/:partnerUuid/theft/:serialnumber" element={privateComponent(<TheftReportScreen />)} />
      <Route path="/:partnerUuid/device/:serialnumber" element={privateComponent(<DeviceDetailScreen />)} />

      <Route path="/:partnerUuid/settings/user" element={privateComponent(<UserSettingsScreen />)} />
      <Route path="/:partnerUuid/settings/partner" element={privateComponent(<PartnerSettingsScreen />)} />
      <Route path="/:partnerUuid/settings/fleets" element={privateComponent(<FleetsScreen />)} />
      <Route path="/:partnerUuid/settings/fleets/:fleetUuid" element={privateComponent(<FleetsDetailScreen />)} />
      <Route path="/:partnerUuid/settings/geofences/create" element={privateComponent(<CreateGeofenceScreen />)} />
      <Route
        path="/:partnerUuid/settings/fleets/:fleetUuid/geofences/:fenceUuid"
        element={privateComponent(<EditFenceScreen />)}
      />
      <Route path="/:partnerUuid/settings/geofences" element={privateComponent(<FenceListScreen />)} />
      <Route path="/:partnerUuid/settings/geofences/create" element={privateComponent(<CreateGeofenceScreen />)} />
      <Route
        path="/:partnerUuid/settings/fleets/:fleetUuid/geofences/:fenceUuid"
        element={privateComponent(<EditFenceScreen />)}
      />

      <Route path="/legal/imprint" element={<ImprintScreen />} />
      <Route path="/legal/privacy" element={<PrivacyPolicyScreen />} />
      <Route path="*" element={<p>There's nothing here: 404!</p>} />
    </Routes>
  )
}

export default ApplicationRouter

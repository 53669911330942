import React, { useEffect } from "react"
import { ListGroup, Form, Row, Col } from "react-bootstrap"
import { Page } from "@src/components"
import { usePartner } from "./usePartner"
import { useTranslation } from "react-i18next"
import { useFleets } from "@src/settings"

const COLUMN = { marginTop: "10rem", borderRadius: 10 }

export const SelectPartnerScreen: React.FC = () => {
  const { partners, changePartner, clearPartner } = usePartner()
  const { updateFleetUuid } = useFleets()
  const { t } = useTranslation()

  // Auto redirect if only one partner is active
  useEffect(() => {
    clearPartner()
    if (partners?.length === 1) changePartner(partners[0].uuid)
    updateFleetUuid("")
  }, [partners])

  return (
    <Page fullscreen>
      <Row className="justify-content-md-center">
        <Col md="8" lg="6" className="shadow-lg bg-body" style={COLUMN}>
          <Form className="mt-4 mb-4 ms-3 me-3 loginform_2">
            <Form.Label className="fw-bold">{t("select_partner_screen.partner_selection").toUpperCase()}</Form.Label>
            <ListGroup defaultActiveKey="#link1">
              {partners?.map((partner, i) => (
                <ListGroup.Item
                  key={i}
                  action
                  onClick={() => {
                    changePartner(partner.uuid)
                  }}>
                  {partner.name}
                </ListGroup.Item>
              ))}
              {partners?.length === 0 && (
                <ListGroup.Item>{t("select_partner_screen.no_partner_available")}</ListGroup.Item>
              )}
            </ListGroup>
          </Form>
        </Col>
      </Row>
    </Page>
  )
}
